import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('auth')

export const authComputed = {
  ...mapState({
    user: (state) => state.user,
  }),
  ...mapGetters([
    'loggedIn',
    'accessToken',
    'isPortalLogin',
    'twoFactorAuthToken',
    'isRtl',
    'isCxoUser',
    'samlRequest',
  ]),
}

export const authMethods = mapActions({
  login: 'login',
  logout: 'logout',
  authInit: 'init',
  refreshUser: 'getUser',
  changeLoginType: 'changeLoginType',
  setToken: 'setToken',
  setSamlRequest: 'setSamlRequest',
  setTwoFcatorAuthToken: 'setTwoFcatorAuthToken',
})
