import api from '@api'
import { isLoggedIn } from '@utils/auth'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformSsoConfig,
  transformSsoConfigForServer,
} from '@data/sso-config'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getSsoConfigApi(usePublic = false) {
  let apiSignature = '/ssoConfig'
  if (usePublic) {
    if (isLoggedIn()) {
      apiSignature = '/myssoconfig'
    } else {
      apiSignature = '/public/ssoConfig'
    }
  }
  return api.get(apiSignature).then(transformSsoConfig)
}

export function updateSsoConfigApi(data) {
  return api
    .patch(`/ssoConfig`, transformSsoConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('sso_configuration'),
      }),
    })
    .then(transformSsoConfig)
}
