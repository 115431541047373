import api from '@api'

export function getProductTypeApi(archived = false, offset, limit) {
  return api
    .post(
      `asset/producttype/search/byqual`,
      {},
      {
        params: {
          ...(archived ? { archived: true } : {}),
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map((s) => ({
        key: s.id,
        text: s.name,
        archived: s.removed,
      }))
    })
}
